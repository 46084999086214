<script setup lang="ts">
const props = defineProps(['item', 'col']);
const reportsStore: any = useReportsStore();
const downloadReport = () => {
  const report = {
    tabs: props.item.tabs,
    name: props.item.name,
    recipients: props.item.recipients,
    schedule: props.item.schedule,
    version: props.item.version,
    org_id: props.item.org_id,
    is_editable: props.item.is_editable,
    created_by: props.item.created_by,
    date_format: props.item.date_format,
    id: props.item.id,
    status: props.item.active ? 'Active' : 'Inactive',
    title: props.item.name,
    owner: props.item.created_by,
    editable: props.item.is_editable,
    active: props.item.active,
    report_type: props.item.report_type,
  };
  reportsStore.downloadReport(report);
};
</script>

<template>
  <div class="table-cell-text-black">
    <v-tooltip
      location="bottom"
      v-bind="props"
    >
      <template #activator="{ props }">
        <div v-bind="props">
          <v-btn
            icon="mdi mdi-download-outline"
            variant="flat"
            size="small"
            @click="downloadReport"
          />
        </div>
      </template>
      <span>{{ $t("Download Report") }}</span>
    </v-tooltip>
  </div>
</template>
