<script setup lang="ts">
const props = defineProps(['item', 'col']);
const reportsStore: any = useReportsStore();

const dateSeparatorsList = ['YYYY-MM-DD', 'DD/MM/YYYY', 'MMM DD'];
const timeSeparatorsList = ['Off', 'HH:mm:ss', 'HH:mm'];

const settingsFormData = ref({
  dateFormat: props.item.date_format,
  timeFormat: timeSeparatorsList[0],
  dateStyle: '',
  formate: '',
});

const changeDateStyleBasedOnDateFormat = () => {
  // const date = useDate();
  // const dateFormatted = date.format(date, settingsFormData.value.dateFormat);
  // const timeFormatted = date.formatTime(date, settingsFormData.value.timeFormat);
  const currentDate = new Date();
  let formattedDate = '';
  let timeFormatted = '';
  const options: any = { month: 'short', day: '2-digit' };
  switch (settingsFormData.value.dateFormat) {
    case 'YYYY-MM-DD':
      formattedDate = currentDate.toISOString().split('T')[0];
      break;
    case 'DD/MM/YYYY':
      formattedDate = currentDate.toLocaleDateString('en-GB');
      break;
    case 'MMM DD':
      formattedDate = currentDate.toLocaleDateString('en-US', options);
      break;
    default:
      break;
  }
  switch (settingsFormData.value.timeFormat) {
    case 'HH:mm:ss':
      timeFormatted = currentDate.toLocaleTimeString('en-US', { hour12: false });
      break;
    case 'HH:mm':
      timeFormatted = currentDate.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
      break;
    default:
      break;
  }
  settingsFormData.value.dateStyle = `${formattedDate} ${timeFormatted}`;
  settingsFormData.value.formate = `${settingsFormData.value.dateFormat} ${settingsFormData.value.timeFormat}`;
};

const onClickSettings = () => {
  changeDateStyleBasedOnDateFormat();
};

const updateDateAndTimeFormat = () => {
  reportsStore.updateDateAndTimeFormatOfReport(props.item.id, settingsFormData.value.dateFormat);
};
</script>

<template>
  <v-dialog
    width="700"
    persistent
    @update:model-value="onClickSettings"
  >
    <template #activator="{ props: activatorProps }">
      <v-tooltip
        location="bottom"
        v-bind="props"
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              v-bind="activatorProps"
              icon="mdi mdi-cog"
              variant="flat"
              size="small"
            />
          </div>
        </template>
        <span>{{ $t("Settings") }}</span>
      </v-tooltip>
    </template>

    <template #default="{ isActive }">
      <v-card title="Settings">
        <v-card-text>
          <div>
            <div class="mb-3">
              <v-select
                v-model="settingsFormData.dateFormat"
                hide-details
                density="compact"
                prepend-icon="mdi mdi-calendar-range"
                label="Select Date Separators"
                variant="outlined"
                class="custom-select"
                :items="dateSeparatorsList"
                @update:model-value="changeDateStyleBasedOnDateFormat"
              />
            </div>
            <div class="mb-3">
              <v-select
                v-model="settingsFormData.timeFormat"
                hide-details
                density="compact"
                prepend-icon="mdi mdi-clock-time-four"
                label="Select Time Separators"
                variant="outlined"
                class="custom-select mt-7"
                :items="timeSeparatorsList"
                @update:model-value="changeDateStyleBasedOnDateFormat"
              />
            </div>
            <div class="mb-3 mx-10">
              <div
                class="font-weight-bold"
                style="color: gray;"
              >
                Date Style
              </div>
              <div>
                {{ settingsFormData.dateStyle }}
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4 mr-3">
          <v-btn
            text="Close"
            color="primary"
            variant="outlined"
            @click="isActive.value = false"
          />
          <v-btn
            prepend-icon="mdi mdi-check"
            text="Save"
            color="primary"
            variant="flat"
            @click="updateDateAndTimeFormat"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
