<script setup lang="ts">
const props = defineProps(['item', 'col']);

const reportsStore: any = useReportsStore();
const scheduleData = ['Weekly Once(Friday)', 'Daily'];

const scheduleReports: any = ref({
  schedule: 'Daily',
  userEmailList: props.item.recipients || [],
});
const modelTitle = `Schedule Reports(Email) - ${props.item.name}`;

const sendEmail = () => {
  const report = {
    tabs: props.item.tabs,
    name: props.item.name,
    recipients: scheduleReports.value.userEmailList,
    schedule: props.item.schedule,
    version: props.item.version,
    org_id: props.item.org_id,
    is_editable: props.item.is_editable,
    created_by: [],
    date_format: props.item.date_format,
    id: props.item.id,
    status: props.item.status,
    title: props.item.name,
    owner: props.item.recipients,
    editable: props.item.editable,
    active: props.item.active,
    report_type: props.item.report_type,
  };
  reportsStore.sendEmail(report);
};

const saveEmail = () => {
  const report = {
    id: props.item.id,
  };

  const data = {
    recipients: scheduleReports.value.userEmailList,
    schedule: props.item.schedule,
  };
  reportsStore.saveEmail(report, data);
};
</script>

<template>
  <v-dialog
    width="700"
    persistent
  >
    <template #activator="{ props: activatorProps }">
      <v-tooltip
        location="bottom"
        v-bind="props"
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              v-bind="activatorProps"
              icon="mdi mdi-email-outline"
              variant="flat"
              size="small"
            />
          </div>
        </template>
        <span>{{ $t("Schedule Report") }}</span>
      </v-tooltip>
    </template>

    <template #default="{ isActive }">
      <v-card :title="modelTitle">
        <v-card-text>
          <div>
            <div class="mb-3">
              <v-select
                v-model="scheduleReports.schedule"
                hide-details
                density="compact"
                prepend-icon="mdi mdi-clock-time-four"
                label="Select Schedule"
                variant="outlined"
                class="custom-select"
                :items="scheduleData"
              />
            </div>
            <div class="mb-3">
              <v-combobox
                v-model="scheduleReports.userEmailList"
                class="mt-7"
                label="Enter Email Address"
                prepend-icon="mdi mdi-email"
                variant="solo"
                chips
                multiple
              >
                <template #selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                  >
                    <strong>{{ item }}</strong>&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4 mr-3">
          <v-btn
            text="Close"
            color="primary"
            variant="outlined"
            @click="isActive.value = false"
          />
          <v-btn
            text="Send Email Now"
            prepend-icon="mdi mdi-check"
            color="primary"
            variant="flat"
            @click="sendEmail"
          />
          <v-btn
            prepend-icon="mdi mdi-check"
            text="Save"
            color="primary"
            variant="flat"
            @click="saveEmail"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
